import {
  Avatar,
  Button,
  Card,
  Col, Divider, Form,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space, Spin, Tag,
  Tooltip,
  Typography
} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useMessageUser from "../../../hooks/useMessageUser";
import {USER_INFO} from "../../../config/config";
import {CopyOutlined } from "@ant-design/icons";
import DpaSelected from "../DpaSelected";
import DpaOption from "../DpaOption";
import LoginPage from "../../Public/LoginPage";
import {hasAccess} from "../../../utils";

const { Paragraph } = Typography;

const { Title, Text } = Typography;

function UserDetail({ useId }: { useId: number }) {

  const { fetchApi, loading } = useFetch();
  const [detail, setDetail] = useState<any>(null);
  const [auth, setAuth] = useState<any>();
  const [dataUserFind, setDataUserFind] = useState<any>({});
  const [codeSync, setCodeSync] = useState<string>('');
  const { t } = useTranslation();
  const { showMessage }  = useMessageUser();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();
  const [endLevel, setEndLevel] = useState<boolean>(false);
  const [filterApply, setFilterApply] = useState<any>({});
  const [configDpaOption, setConfigDpaOption] = useState<any>({});
  const [addCommunities, setAddCommunities] = useState<boolean>(false);

  useEffect(() => {
    const dataUser = JSON.parse(localStorage.getItem(USER_INFO) as string);
    setAuth(dataUser);
  }, []);

  useEffect(() => {
    getUserById();
  }, [useId]);

  console.log(dataUserFind);

  const getUserById = () => {
    const dataSend: FetchApiInterface = {
      url: `/users/find_by_id/${useId}`,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setDataUserFind(dataResponse);
          setCodeSync(dataResponse?.useSyncCode);
          setAccessLevels(dataResponse);
        } else {
          showMessage(data?.message, 'error');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const setAccessLevels = (userData: any) => {
    const roleNames = userData?.roles.map((role: any) => role.rolName);

    if (roleNames.includes('ROLE_GUEST')) {
      setConfigDpaOption({
        activeMultiple: true,
        maxLevel: null,
        dpaId: userData?.dpa?.dpaId,
      })
    }
    if (roleNames.includes('ROLE_USER')) {
      setConfigDpaOption({
        activeMultiple: true,
        maxLevel: null,
        dpaId: userData?.dpa?.dpaId,
      })
    }
    if (roleNames.includes('ROLE_COUNTRY_ADMIN') || roleNames.includes('ROLE_COUNTRY_PROCESS')) {
      setConfigDpaOption({
        activeMultiple: true,
        maxLevel: 1,
        dpaId: userData?.dpa?.dpaId,
      })
    }
    if (roleNames.includes('ROLE_SYSADMIN')) {
      setConfigDpaOption({
        activeMultiple: true,
        maxLevel: 1,
      })
    }
    if (roleNames.includes('ROLE_GLOBAL_ADMIN')) {
      setConfigDpaOption({
        activeMultiple: true,
        maxLevel: 1,
      })
    }
    if (roleNames.includes('ROLE_REGION_ADMIN')) {
      setConfigDpaOption({
        activeMultiple: true,
        maxLevel: 1,
        dpaId: userData?.dpa?.dpaId,
      })
    }
    setDetail(userData);
  }

  const confirm = () => {
    const dataSend: FetchApiInterface = {
      url: `/users/reset_password/${detail?.useEmail}`,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
        } else {
          showMessage(data?.message, 'warning');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message || 'Error en la solicitud.', 'error');
    });
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(codeSync);
      notification.success({ message: t('copyTextSuccess') });
    } catch (err) {
      notification.error({ message: t('copyTextError') });
    }
  };

  const generateCode = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  }

  const sendCodeToBackend = () => {
    // Generar el código
    const code = generateCode();

    // Datos a enviar al backend
    const dataSend: FetchApiInterface = {
      url: '/users/sync_pin',
      data: {
        usuId: useId,
        usePin: code
      }
    };

    // Realizar la solicitud
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setCodeSync(code);
          showMessage(data?.message, 'success');
          setDetail(data?.data);
        } else {
          showMessage(data?.message, 'warning');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message || 'Error en la solicitud.', 'error');
    });
  }

  const save = (values: any) => {
    if (!endLevel && addCommunities && values?.dpa?.length < 0) {
      notification.warn({
        message: 'Para asegurar el correcto funcionamiento del aplicativo, por favor seleccione un DPA del más bajo nivel.'
      });
      return;
    }
    const communities: any = [];

    values.dpa.forEach((dpa: any) => {
      communities.push({ dpaId: dpa });
    });

    let dataConfig: any = {
      useName: detail?.useName,
      useLastName: detail?.useLastName,
      useEmail: detail?.useEmail,
      rolId: detail.role,
      statusRegister: detail?.statusRegister,
      countryDpaId: null,
      usuId: detail.useId,
      communities: communities,
      // todo borrar
      hasComms: dataUserFind?.hasComms,
      hasAbc: dataUserFind?.hasAbc,
    }
    const dataSend: FetchApiInterface = {
      url: '/users/update_user',
      data: dataConfig
    }
    console.log(dataSend);
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setAddCommunities(false);
          showMessage(data?.message);
          getUserById();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message || 'generalServerError', 'error');
    });
  }

  useEffect(() => {
    if (endLevel) {
      form.resetFields(['dpa']);
    }
  }, [endLevel]);

  const deleteDpa = (item: any) => {
    const dataSend: FetchApiInterface = {
      url: `/users/delete_user_dpa/${item.udpId}`
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          getUserById();
          showMessage(data?.message, 'success');
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return (
    <Row>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><UserOutlined /> {t('userDetailTitle')}</Title>
          <Row className='mt-1' gutter={20}>
            <Col span={24} md={8} lg={6} xxl={4} className='text-center'>
              <Space direction='vertical' align='center'>
                <Avatar size={60} className='bg-primary'>
                  { detail?.useName?.charAt(0) }
                </Avatar>
                <Text strong>
                  { detail?.useName } { detail?.useLastName }
                </Text>
                <Text>
                  { detail?.rolName }
                </Text>
              </Space>
              <Space direction='vertical'>
                {
                  (detail?.roles[0]['rolName'] === "ROLE_GUEST") && (
                    <Popconfirm
                      title={t('descriptionChangePass')}
                      onConfirm={confirm}
                      okText={t('btnYes')}
                      cancelText={t('btnNo')}
                    >
                      <Button type='link' ghost danger htmlType='button'>{t('btnResetPassUser')}</Button>
                    </Popconfirm>
                  )
                }
                {
                  (hasAccess(['ROLE_SYSADMIN','ROLE_GLOBAL_ADMIN','ROLE_REGION_ADMIN', 'ROLE_COUNTRY_ADMIN', 'ROLE_COUNTRY_PROCESS', 'ROLE_USER'])) && (
                    <>
                      <Tooltip title={t('textGetCode')}>
                        <Button type='link' ghost htmlType='button' onClick={() => setIsModalOpen(true)}>{t('btnGetCode')}</Button>
                      </Tooltip>
                      <Modal title={t('textGetCode')} open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
                        <Spin spinning={loading}>
                          <div className="text-center">
                            <Space>
                              <Title level={3}>{ codeSync }</Title>
                              <Button type='link' htmlType='button' icon={<CopyOutlined />} onClick={handleCopy} />
                            </Space>
                            <br/>
                            <Button type='primary' htmlType='button' onClick={sendCodeToBackend}>{t('btnGenerateCode')}</Button>
                          </div>
                        </Spin>
                      </Modal>
                    </>
                  )
                }
              </Space>
            </Col>
            <Col span={24} md={16} lg={18} xxl={20}>
              <Row justify='space-between' className='mb-1'>
                <Col flex='auto'>
                  {t('userDetailEmail')}
                </Col>
                <Col>
                  <Text strong>{ detail?.useEmail }</Text>
                </Col>
              </Row>
              <Row justify='space-between' className='mb-1'>
                <Col flex='auto'>
                  {t('userRoles')}
                </Col>
                <Col>
                  <div>
                    {detail?.roles?.map((role: any, index: number) => (
                      <Tag key={index} color="blue">
                        <Tooltip title={role?.rolName}>
                          {role.rolLabel}
                        </Tooltip>
                      </Tag>
                    ))}
                  </div>
                </Col>
              </Row>
              {
                (auth?.roles.includes("ROLE_SYSADMIN")) && (
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('useDetailPin')}
                    </Col>
                    <Col>
                      <Text strong>{ detail?.usePin }</Text>
                    </Col>
                  </Row>
                )
              }
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col flex='auto'>
              <Paragraph strong>{t('titleList')}</Paragraph>
              <div>
                <Row gutter={[5, 5]}>
                  {
                    detail?.userDpas?.map((dpa: any, index: number) => (
                      <Col key={index} span={24}>
                        <Space>
                          <Popconfirm
                            title={t('titleDeleteConfirmDpa')}
                            okText={t('btnYes')}
                            cancelText={t('btnNo')}
                            onConfirm={() => deleteDpa(dpa)}
                          >
                            <Button size='small' danger>{ t('btnDelete') }</Button>
                          </Popconfirm>
                          { dpa.dpa.dpaName }
                        </Space>
                      </Col>
                    ))
                  }
                </Row>
              </div>
            </Col>
            {
              (detail) && (
                <Col span={24} className='text-right'>
                  <br/>
                  {
                    (hasAccess(['ROLE_COUNTRY_PROCESS','ROLE_USER'])) && (
                      <div className='text-right mb-2'>
                        <Button type='primary' ghost onClick={() => setAddCommunities(!addCommunities)}>{t('btnAddCommunities')}</Button>
                      </div>
                    )
                  }
                </Col>
              )
            }
            {
              (addCommunities) && (
                  <Col span={24}>
                    <Form onFinish={save} form={form}>
                      <Divider />
                      <DpaOption
                        form={form}
                        setEndLevel={setEndLevel}
                        activeMultiple={configDpaOption?.activeMultiple || false}
                        setFilterApply={setFilterApply} filterApply={filterApply}
                        maxLevel={ configDpaOption?.maxLevel }
                        dpaId={ configDpaOption?.dpaId || null }
                      />
                      <Button type='primary' htmlType='submit'>{t('btnSaveNewCommunities')}</Button>
                    </Form>
                  </Col>
              )
            }
          </Row>
        </Card>
      </Col>

    </Row>
  );
}

export default UserDetail;
